import React from "react"

import { GrDocumentPdf } from "react-icons/gr";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"

import betreuungsAntrag from "../../assets/2021-06-02_AnmeldungBetreuung.pdf"

const betreuung = () => (
  <Layout>
    <SEO title="Betreuung"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="BETREUUNG" motto="DIE BETREUUNGSGRUPPE DER GS LÖHNE-BAHNHOF"/>
      <ElternInfoSubmenu/>

      <article id="con_2_rechts">

        <h3 className="small_sub"><span
          style={{ color: "#007c34" }}>Betreuungsgruppe der Grundschule L&ouml;hne-Bahnhof</span>
        </h3>

        <p className="text"><span style={{ fontWeight: "bold" }}>Die Betreuungsgruppe der Grundschule L&ouml;hne-Bahnhof ist ein durch die Eltern getragener gemeinn&uuml;tziger Verein. Das Angebot des Vereins umfasst die verl&auml;ssliche Betreuung der Kinder vor und nach dem Unterricht in der Zeit von 7:00 Uhr bis 14:30 Uhr.</span><br/><br/>
          Somit tr&auml;gt das Angebot dazu bei, dass insbesondere berufst&auml;tige Eltern ihren Alltag besser planen
          und
          bew&auml;ltigen k&ouml;nnen. Das Angebot ist flexibel nutzbar und auf die Unterrichtszeiten aller Klassen
          abgestimmt. Es kann somit gut den individuellen Bed&uuml;rfnissen angepasst werden. So kann z. B. das Angebot
          der Betreuung ab 7:00 Uhr bis zum Schulbeginn auch mit dem Angebot der offenen Ganztagsgrundschule
          kombiniert werden. <br/><br/>
          Die R&auml;umlichkeiten der Gruppe sind in drei Bereiche eingeteilt. Zum einen k&ouml;nnen die Kinder sich in
          unserem Toberaum nach der Schule einmal so richtig austoben. Hier darf es nach einem konzentrierten
          Vormittag im Unterricht ruhig auch mal lauter werden. Im Aufenthaltsraum gibt es die M&ouml;glichkeit zu
          spielen, zu basteln oder sich einfach mal zu unterhalten. Hier finden die Kinder neben Spielsachen auch
          alles, was man z. B. zum Malen ben&ouml;tigt. Der dritte Bereich ist unsere Ruhezone. Hier k&ouml;nnen sich
          die
          Kinder zur&uuml;ckziehen, um zu lesen oder schon einmal mit den Hausaufgaben zu beginnen. Letzteres ist
          insbesondere f&uuml;r die Kinder der dritten und vierten Klasse gedacht, da die Kinder hier schon in der Lage
          sind selbst&auml;ndiger zu arbeiten.
          <br/><br/>
          Bei sch&ouml;nem Wetter nutzen wir nat&uuml;rlich auch die Au&szlig;enanlagen auf dem
          Schulgel&auml;nde.
        </p>
        <br/><br/>
        <span style={{ fontWeight: "bold" }}
              className="text">Das Betreuungsteam besteht neben dem ehrenamtlich t&auml;tigen Vorstand aus vier Betreuerinnen, die sich w&auml;hrend der &Ouml;ffnungszeiten um die Kinder k&uuml;mmern. Die monatlichen Beitr&auml;ge belaufen sich aktuell auf 40,00 &euro; f&uuml;r das erste Kind und 20,00 &euro; f&uuml;r jedes weitere Kind.</span>
        <br/><br/>

        <a className="mehr_gruen text" href={betreuungsAntrag} target="_blank"><GrDocumentPdf style={{
          width: "1.5em",
          height: "1.5em",
          marginRight: "0.5em",
          verticalAlign: "bottom",
        }}/> Beitrittserklärung für den Elternverein</a><br/>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default betreuung
